import Link from 'next/link';
import type { FunctionComponent } from 'react';
import { Markdown } from '../../../../components/markdown';
import type { BentoPieceRectangleBroadbandFactsFragment } from '../../../../graphql/queries/GetBentoPieceRectangleBroadbandFacts.graphql';
import { usePersonalizations } from '../../../../lib/hooks/usePersonalizations';
import { renderTemplate } from '../../../../lib/utils/text';
import styles from './bento-rectangle-broadband-facts.module.scss';
export const BentoRectangleBroadbandFacts: FunctionComponent<Props> = ({
  heading,
  subheadingSection,
  monthlyPrice,
  dataIncluded,
  policyLink = ''
}) => {
  const personalizations = usePersonalizations();
  return <div className={styles.boxOne} data-sentry-component="BentoRectangleBroadbandFacts" data-sentry-source-file="bento-rectangle-broadband-facts.tsx">
            <div className={`${styles.boxOneContent}`}>
                <h5 className={styles.title}>{heading}</h5>

                <div className={styles.separator} />

                <div className={styles.markdownSection}>
                    <Markdown tag='p' typeStyle='body-sm' markdown={renderTemplate(subheadingSection, personalizations)} data-sentry-element="Markdown" data-sentry-source-file="bento-rectangle-broadband-facts.tsx" />
                </div>

                <div className={styles.separatorThick} />

                <div className={styles.markdownSection}>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>Monthly Price</div>
                        <div className={styles.dataValue}>${monthlyPrice}</div>
                    </div>
                </div>

                <div className={styles.separator} />

                <div className={styles.markdownSection}>
                    <p>
                        This Monthly Price is not an introductory rate. <br />
                        This Monthly Price does not require a long-term
                        contract.
                    </p>
                </div>

                <div className={styles.separator} />

                <div className={styles.markdownSection}>
                    <h5 className={styles.sectionTitle}>
                        Additional Charges &amp; Terms
                    </h5>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            Provider Monthly Fees
                        </div>
                        <div className={styles.dataValue}>None</div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            One-time Fees at the Time of Purchase
                        </div>
                        <div className={styles.dataValue}>None</div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            Early Termination Fee
                        </div>
                        <div className={styles.dataValue}>None</div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>Government Taxes</div>
                        <div className={styles.dataValue}>
                            Varies by Location
                        </div>
                    </div>
                </div>

                <div className={styles.separator} />

                <div className={styles.markdownSection}>
                    <h5 className={styles.sectionTitle}>
                        Speeds Provided with Plan 5G
                    </h5>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            Typical Download Speed
                        </div>
                        <div className={styles.dataValue}>30.5 - 389 Mbps</div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            Typical Upload Speed
                        </div>
                        <div className={styles.dataValue}>4.5 - 33 Mbps</div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>Typical Latency</div>
                        <div className={styles.dataValue}>11 - 35 ms</div>
                    </div>
                </div>

                <div className={styles.separator} />

                <div className={styles.markdownSection}>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            <span className={styles.colorPink}>
                                Data Included
                            </span>{' '}
                            with Monthly Price
                        </div>
                        <div className={styles.dataValue}>{dataIncluded}</div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            Charges for Additional Data Usage
                        </div>
                        <div className={styles.dataValue}>None</div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            Read our acceptable use policy{' '}
                        </div>
                        <div className={styles.dataValue}>
                            <Link href={policyLink} passHref target='_blank' className={styles.colorPink} data-sentry-element="Link" data-sentry-source-file="bento-rectangle-broadband-facts.tsx">
                                Read our Policy
                            </Link>
                        </div>
                    </div>
                </div>

                <div className={styles.separator} />

                <div className={styles.markdownSection}>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>
                            Network Management
                        </div>
                        <div className={styles.dataValue}>
                            <Link href={policyLink} passHref target='_blank' className={styles.colorPink} data-sentry-element="Link" data-sentry-source-file="bento-rectangle-broadband-facts.tsx">
                                Read our Policy
                            </Link>
                        </div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>Privacy</div>
                        <div className={styles.dataValue}>
                            <Link href={policyLink} passHref target='_blank' className={styles.colorPink} data-sentry-element="Link" data-sentry-source-file="bento-rectangle-broadband-facts.tsx">
                                Read our Policy
                            </Link>
                        </div>
                    </div>
                </div>

                <div className={styles.separatorThick} />

                <div className={styles.markdownSection}>
                    <h5 className={styles.sectionTitle}>Customer Support</h5>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>Customer Support</div>
                        <div className={styles.dataValue}>hello@step.com</div>
                    </div>
                </div>

                <div className={styles.separator} />

                <div className={styles.markdownSection}>
                    <div className={styles.dataContainer}>
                        <div className={styles.dataEntry}>Provider Name</div>
                        <div className={styles.dataValue}>Gigs</div>
                    </div>
                </div>

                <div className={styles.separator} />

                <div className={styles.markdownSection}>
                    <p>
                        Learn more about the terms used on this label by
                        visiting the Federal Communications Commission’s
                        Consumer Resource Center.
                    </p>
                    <br />
                    <Link href='https://www.fcc.gov/consumers' passHref target='_blank' className={styles.link} data-sentry-element="Link" data-sentry-source-file="bento-rectangle-broadband-facts.tsx">
                        https://fcc.gov/consumers
                    </Link>
                    <br />
                    <p>pln_0UhKaiyz0d0aVs556LXt4q7yTVtM</p>
                </div>

                <div className={styles.separator} />
            </div>
        </div>;
};
export default BentoRectangleBroadbandFacts;
type Props = Omit<BentoPieceRectangleBroadbandFactsFragment, 'sys' | 'type'>;